/* scroll bar style */

/* Define scrollbar styles */
::-webkit-scrollbar {
  width: 12px; /* Adjust width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Round the corners of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2; /* Color of the scrollbar track */
}

/* For Firefox */
/* Note: Firefox doesn't support the above pseudo-elements, so you can use the following */
/* For a complete solution, you may need to use JavaScript for cross-browser compatibility */

/* Width of the scrollbar */
* {
  scrollbar-width: inherit;
  scrollbar-color: #888 #f2f2f2;
}

.loginDiv {
  background: #d9d9d999;
  border-radius: 16px;
  height: 330px;
  width: 100%;
}

.minWidth {
  min-width: 500px !important;
}

.passwordAndEmailInputs {
  outline: none;
  background-color: #ffffff;
  color: #000;
  width: 100%;
  border-radius: 50px;
  border: none;
  height: 39px;
  padding-left: 20px;
  font-family: Open Sans;
}

/* .containerLoginSignUp {
  height: 440;
  background-color: "#fff";
} */
.passwordAndEmailInputs2 {
  outline: none;
  background-color: #f2f2f2;
  color: #000;
  width: 90%;
  border-radius: 50px;
  border: none;
  height: 39px;
  padding-left: 20px;
  font-family: Open Sans;
}

.passwordAndEmailInputs::placeholder {
  color: #444748; /* Placeholder text color */
  font-family: Open Sans;
}

.landingPageLoginButton {
  cursor: pointer;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #2c6fef;
  border: none;
  border-radius: 50px;
  height: 39px;
  width: 90%;
}
.landingPageCreationAccountButton {
  cursor: pointer;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #2c6fef;
  border: none;
  border-radius: 50px;
  height: 39px;
  width: 100%;
}

.landingPageLoginButton:hover {
  background-color: #ffffff;
  border: 1px solid #2c6fef;
  color: #2c6fef;
}

.landingPageButton {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #f26369;
  border: none;
  border-radius: 50px;
  width: 30%;
  height: 39px;
}
.answerCompButton {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #f26369;
  border: none;
  border-radius: 50px;
  width: 90%;
  height: 39px;
}
.answerCompButton:hover {
  color: #f26369;
  background-color: #ffffff;
}

/* .closingButtonClass {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #f26369;
  border: none;
  border-radius: 50px;
  width: 30%;
  height: 39px;
} */
.closingButtonClass:hover {
  background-color: #ffffff;
  color: #2c6fef;
  border: 1px solid #2c6fef;
  font-weight: 500;
}
.loginSignUpDiv {
  display: flex;
  /*width:100%;*/
  /*height: 275px; /* Adjust the height as needed */
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.ACCFocusInput {
  border-color: #2c6fef;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 1px #2c6fef;
}

@media (max-width: 1400px) {
  .imageSize {
    width: 105%;
  }
}

@media (max-width: 400) {
  .landingPageButton {
    width: 100%;
  }
}
@media (max-width: 800px) and (min-width: 401px) {
  .landingPageButton {
    width: 50%;
  }
}
.landingPageButton:hover {
  background-color: #ffffff;
  color: #f26369;
  font-weight: 500;
}

.benchMarkingDiv {
  height: 700px;
  width: 100%;
  background-color: #183d82;
}

.benchMarkingDiv img {
  transition: transform 0.3s ease-in-out;
}

.benchMarkingDiv img:hover {
  transform: translateY(-10px);
}

.epsiTopics {
  width: 100%;
  height: 700px;
}

.epsiTopics img {
  transition: transform 0.3s ease-in-out;
}

.epsiTopics img:hover {
  transform: translateY(-10px);
}

.epsiFooter {
  padding-bottom: 30px;
  width: 100%;
  background-color: #0d41a6;
}

.emailLinkButton {
  margin-top: 10px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 50px;
  width: 250px;
  height: 49px;
  background-color: #2c6fef;
}

.emailLinkButton:hover {
  background-color: #ffffff;
}

.emailLinkAnchor {
  color: #ffffff;
  text-decoration: none;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.emailLinkAnchorHover {
  text-decoration: none;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #2c6fef;
}

.line {
  height: 1px; /* Adjust the thickness of the line as needed */
  width: 100%; /* Adjust the width of the line */
  background-color: #000; /* Adjust the color of the line */
}
.mediumline {
  height: 0.7px; /* Adjust the thickness of the line as needed */
  width: 100%; /* Adjust the width of the line */
  background-color: #000; /* Adjust the color of the line */
}

.thinLine {
  height: 0.5px; /* Adjust the thickness of the line as needed */
  width: 100%; /* Adjust the width of the line */
  background-color: #000; /* Adjust the color of the line */
}

.topicsRangeLabel {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #0d41a6;
}

.signUpInputs {
  background-color: #f5f6f7;
  font-family: Open Sans;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 50px;
  padding-left: 20px;
  height: 39px;
  width: 230px;
}

.signUpInputs::placeholder {
  color: #7b7b7b; /* Placeholder text color */
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.swal-wide {
  width: 660px !important;
  border-radius: 5%;
}
.swal-delete-paymentmethod {
  height: 250px !important;
  border-radius: 50px;
}
.swal-update-profile {
  width: 550px !important;
  height: 320px !important;
  border-radius: 5%;
}
.swal-height {
  height: 275px !important;
  border-radius: 5%;
}
.swal-height-activationLink {
  height: 420px !important;
  border-radius: 5%;
}
.swal-forgot-pass-success {
  height: 370px !important;
  border-radius: 5%;
}

.swal-feedbackNegatif {
  height: 360px !important;
  width: 700px !important;
  border-radius: 20px;
}

.swal-feedbackPositif {
  height: 360px !important;
  width: 700px !important;
  border-radius: 20px;
}

.feedBackTextArea::placeholder {
  font-family: Open Sans;
  font-size: inherit;
  line-height: inherit;
}

.feedBackTitleDiv {
  padding: 0px;
  border-bottom: 1px solid #6c757d;
  text-align: left;
}

.feedBackTextArea {
  width: 100%;
  height: 150px;
  max-height: 150px;
  margin: 0;
  padding: 0;
  padding-left: 30px !important;
  padding-top: 15px !important;

  /* Reset border properties */
  border-radius: 10px;
  border: 1px solid lightgray;
  /* Reset font properties */
  font-family: Open Sans;
  font-size: inherit;
  line-height: inherit;

  /* Reset other styles */
  resize: none;
}

.feedBackTextArea:focus {
  border-color: lightgray;
  outline: none; /* Change the border color on focus */
}

.submitFeedBackButtonDiv {
  padding: 0px;
  text-align: center;
  margin: 0;
}

.submitFeedBackButton {
  font-family: Open Sans;
  font-size: inherit;
  line-height: inherit;
  font-size: 14px;
  border-radius: 50px;
  background-color: #2c6fef;
  color: #fff;
  border: 1px solid #2c6fef;
  max-width: 200px;
  width: 100%;
  padding: 10px;
  margin: 0;
}

.submitFeedBackButton:hover {
  background-color: #fff;
  color: #2c6fef;
  border: 1px solid #2c6fef;
}

.otherThanNamesSignUpInputs {
  width: 100% !important;
}

.privatePolicyLabel {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #7b7b7b;
}

.termsOfPolicyAnchor {
  text-decoration: none;
}

.landingPageConfirmSignUpButton {
  width: 100%;
  border: 1px solid #f26369;
}
.landingPageCancelSignUpButton {
  color: #f26369;
  width: 100%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #f26369;
  border-radius: 50px;
  height: 39px;
}

.headerButtons {
  margin-left: 96px;
  height: 62px;
  width: 119px;
  border: none;
  background-color: transparent;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  margin-right: -85px;
}

.headerButtons:hover {
  background-color: #2c6fef;
}

.sideMenucolumn {
  max-height: 140vh;
  background-color: #000;
  overflow: scroll;
  width: 23% !important;
  overflow-x: hidden;
}
.sideMenucolumn3 {
  max-height: auto;
  background-color: #000;
  overflow: scroll;
  width: 23% !important;
  overflow-x: hidden;
}
.sideMenucolumnInBilling {
  min-height: 100vh;
  background-color: #000;
  overflow: scroll;
  width: 23% !important;
  overflow-x: hidden;
}

/* .chatBotSideMenu {
  height: 91vh;
} */

@media (min-height: 400px) and (max-height: 1170px) {
  .chatBotSideMenu {
    height: 91.4vh;
  }
}
@media (min-height: 1171px) {
  .chatBotSideMenu {
    height: 105vh;
  }
}

.sideMenuOption {
  cursor: pointer;
  height: 55px;
  width: 100%;
  padding-top: 15px;
}

.sideMenuOption:hover {
  cursor: pointer;
  background-color: #444748;
  height: 55px;
  width: 100%;
  padding-top: 15px;
}
.sideMenuOption2 {
  cursor: pointer;
  min-height: 55px;
  height: auto;
  width: 100%;
  padding-top: 15px;
}

.sideMenuOption2:hover {
  cursor: pointer;
  background-color: #444748;
  min-height: 55px;
  height: auto;
  width: 100%;
  padding-top: 15px;
}

.sideMenuChatHistoryItem {
  cursor: pointer;
  height: 55px;
  width: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.sideMenuChatHistoryItem:hover {
  background: #444748;
}

.firstSideMenuOptionBorder {
  border-top: 0.1px solid grey;
  border-bottom: 0.1px solid grey;
}
.nextSideMenuOptionBorder {
  border-bottom: 0.1px solid grey;
}
.thirdSideMenuOptionBorder {
  border-bottom: 0.1px solid grey;
}
.fourthSideMenuOptionBorder {
  border-bottom: 0.1px solid grey;
}
.sideMenuSelected {
  background: #444748;
}
.updateProfileInputs {
  /* border: 0.5px solid #a9a5a6;*/
  border: none;
  width: 64%;
  height: 140%;
  border-radius: 50px;
  padding-left: 15px;
  outline: none;
  background-color: #f2f2f2; /* Light grey background */
}

.updateProfileInputs:disabled {
  background-color: #f2f2f2; /* Light grey background */
  color: #a9a5a6; /* Dark grey text color */
  cursor: not-allowed; /* Change cursor to indicate not-allowed */
}
.updateProfileApplyButton {
  color: #ffffff;
  background-color: #2c6fef;
  border-radius: 50px;
  border: none;
  height: 40px;
  width: 64%;
}
.passwordChangeSuccesfullDiv {
  margin-left: 52px;
  width: 23%;
  background-color: #a6c392;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.passwordChangeSuccesfullSpan {
  color: #75905a;
}
.updateProfileApplyButton:hover {
  color: #2c6fef;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #2c6fef;
}
.updateProfileDeleteButton {
  color: #ffffff;
  background-color: #f26369;
  border-radius: 50px;
  border: none;
  height: 40px;
  width: 64%;
}
.updateProfileDeleteButton:hover {
  color: #f26369;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #f26369;
}

.benchMarkingButtonsNotSelected {
  display: inline-block;
  border-radius: 10px;
  width: 100%;
  height: 51px;
  flex-shrink: 0;
  background-color: #fff;
  color: #000;
  font-family: Open Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #b6b7b9;
}

.benchMarkingButtonsNotSelected:hover {
  background-color: #7b7b7b24;
}

.benchMarkingButtonsSelected {
  border-radius: 10px;
  width: 100%;
  height: 51px;
  flex-shrink: 0;
  color: #ffffff;
  color: #000;
  font-family: Open Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #7b7b7b24;
  border: 1px solid #b6b7b9;
}

.benchMarkingTitlesDivRed {
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #f9b9bb;
  border: none;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
}

.benchMarkingTitlesDivGreen {
  width: 618px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #d0e4c2;
  border: none;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
}

.frame {
  margin-top: 20px;
  border: 2px solid black; /* Adjust the frame border properties */
  /* Adjust the padding inside the frame */
  width: 100%; /* Adjust the width of the frame */
  height: 100px;
  border-top: none;
  /* Adjust the margin for centering the frame */
}

.tab-header {
  display: flex;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;

  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
  border-top-left-radius: 20px; /* Rounded top left corner */
  border-top-right-radius: 20px;
  background-color: #ededed;
}

.tab-item.active {
  background-color: #fff;
  border-bottom: none;
}

.tab-content {
  border: 1px solid #ccc;
  border-top: none;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

.billingButton {
  width: 170px;
  height: 36px;
  border-radius: 50px;
  background-color: #d9d9d9;
  border: none;
  font-family: Open Sans;
  font-size: 10pt;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.newChatButtonDiv {
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  height: 72px;
  border: none;
}
.newChatButtonDiv:hover {
  background: linear-gradient(0deg, #444748, #444748),
    linear-gradient(0deg, rgba(68, 71, 72, 0.57), rgba(68, 71, 72, 0.57));
}

.custom-position-swal-edit-history {
  height: 120px;
  width: 220px;
  padding: 0 !important;
}

.rectangle {
  height: 102px;
  width: 185px;
  background-color: white;
  position: absolute;
  border-radius: 10px;
  margin-left: 20px;
}

@media (max-width: 500px) {
  .alignToolTip {
    margin-right: 60px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1800px) {
  .alignToolTip {
    margin-right: 79px !important;
  }
}
/*@media (min-width: 1000) {
  .alignToolTip {
    margin-right: 79px;
  }
} */

.chatBotToolTip {
  height: 150px;
  /* margin-right: 79px; */
  width: 150px;
  background-color: rgb(252, 243, 243);
  border-radius: 10px;
  border: 1px solid #b6b7b9;
  background-color: #f5f5f5;
}

/* .test {
  width: max-content!important;
} */
.custom-position-swal-options-send {
  /* position: absolute;
  top: 300px;
  left: 1200px; */
  width: 220px;
}

.rename-delete-popup {
  cursor: pointer;
  height: 37px;
}
.rename-delete-popup:hover {
  background-color: #ebebeb;
}
.centerLeft {
  text-align: left !important;
}

.myInvisibleInput {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  height: 100%;
  box-sizing: border-box;
}

.circle-chat {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3498db; /* Choose your desired background color */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Choose your desired text color */
  font-weight: bold;
  font-size: 16px;
}

.sendMessageTextArea {
  width: 100%;
  height: 120%;
  padding-left: 30px;
  padding-top: 20px;
  border-radius: 10px;
  outline: none;
  background-color: #fef3f6;
}

/* .myTextAreaDiv {
  width: 100%;
  border: 1px solid gray;
  width: 15em;
  height: 5em;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.editable-content {
  width: 100%;
  height: 75px;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: 120px;
  min-height: 40px; /* Set an appropriate minimum height */
  border: 1px solid #ccc; /* Add border or other styles as needed */
  padding: 5px;
  padding-left: 30px;
}

.editable-content:empty:before {
  content: "Write your question here";
  color: #999; /* Set the color of the placeholder text */
  font-family: Open Sans;
}

.editable-content:focus {
  border: 1px solid #ccc; /* Set the desired border color on focus */
}

.textAreaDiv {
  width: 100%;
  height: 85px;
  border-radius: 10px;
  background-color: #f5f5f5;
  border: 1px solid #b6b7b9;
}
.textAreaDivFocused {
  border: 1px solid #908a8a;
}

.myMessageTextArea {
  background-color: transparent;
  box-sizing: content-box;
  width: 85%;
  height: 100%;
  max-height: 65px;

  /* Remove margins and padding */
  margin: 0;
  padding: 0;
  padding-left: 30px !important;
  padding-top: 15px !important;

  /* Reset border properties */
  border: none;
  border-radius: 0;

  /* Reset font properties */
  font-family: Open Sans;
  font-size: inherit;
  line-height: inherit;

  /* Reset other styles */
  resize: none;
  outline: none;
}

.myMessageTextArea:focus {
  outline: 0;
}

/* dynamic word display css */

.typing-effect-container {
  margin-left: 43px;
  font-family: "Noto Serif", serif;
  font-size: 14px;
  font-weight: 500;
  white-space: wrap;
}

.typing-effect-container p {
  margin: 0;
  padding: 0;
  white-space: pre-wrap; /* Allow wrapping on the whitespace */
  overflow-wrap: break-word; /* Break words when needed */
  overflow: hidden;
  position: relative;
  animation: typing 0.8s steps(40, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.cursor {
  display: inline-block;

  width: 0.2em;
  height: 1em;
  background-color: #000;
  margin-left: 2px; /* Adjust the cursor position */
}

.cursor-hidden {
  background-color: transparent;
}

/* end of dynamic word display css */

.editMessageButton {
  background-color: transparent;
  border: none;
  color: #2c6fef;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 30px;
  height: 100%;
}

.editMessageButtonClicked {
  height: 100%;
  border-radius: 50px !important;
  border: none !important;
  background-color: #85c8f500 !important;
}
.editMessageButton:hover {
  height: 100%;
  border-radius: 50px;
  border: none;
  background-color: #85c8f500;
}

.submitMessageEditButton {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #2c6fef;
  border: none;
  border-radius: 50px;
  height: 30px;
  width: 90px;
}
.cancelMessageEditButton {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #2c6fef;
  border: 1px solid #2c6fef;
  background-color: #ffffff;
  border-radius: 50px;
  height: 30px;
  width: 90px;
}

.senderLabelAreaEdit {
  min-height: 46px;
  width: 100%;
  border: none;
  background-color: transparent;
  resize: auto;
  margin-left: 43px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}

.senderLabelAreaEdit:disabled {
  opacity: 1; /* Reset opacity */
  cursor: pointer; /* Reset cursor style */
  color: #000;
}

.senderLabelAreaEdit:focus {
  outline: none !important;
}

.cancelPaymentMethod {
  background-color: #d9d9d9;
  border-radius: 50px;
  border: none;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  height: 170%;
  width: 110%;
}
.addPaymentMethod {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #2c6fef;
  border-radius: 50px;
  border: none !important;
  height: 170%;
  width: 110%;
}
.swalConfirmButton {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #2c6fef;
  border-radius: 50px;
  border: none !important;
  height: 40px;
  width: 150px;
}

.currencyDiv {
  width: 50px; /* Adjust the width as needed */
  height: 65px; /* Adjust the height as needed */
  background-color: #efefef; /* Set the background color */
  color: #ffffff; /* Set the text color */
  text-align: center; /* Center the text horizontally */
  display: table;
  border-top-left-radius: 10px; /* Adjust the top-left corner radius */
  border-bottom-left-radius: 10px; /* Adjust the bottom-right corner radius */
}

.selectAmountInput {
  width: 100%;
  font-size: 20pt;
  border-top-right-radius: 10px; /* Adjust the top-left corner radius */
  border-bottom-right-radius: 10px;
  border-radius: 0px, 10px, 10px, 0px;
  border: 1px solid #d9d9d9;
}

.selectAmountInput:focus {
  outline: none;
}

.card-container {
  border: 1px solid #ddd; /* Border style */
  padding: 10px; /* Padding around the content */
  margin-bottom: 10px; /* Margin between cards */
  border-radius: 10px;
}

.cardContainerSelected {
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #2c6fef;
  background-color: #eef4ff;
}

.classPointer {
  cursor: pointer;
}

.cancelAddCreditBalanceLabel {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  border: none;
  background-color: #d9d9d9;
  color: #000;
}
.continueAddCreditBalanceLabel {
  width: 120%;
  height: 40px;
  border-radius: 50px;
  border: none;
  background-color: #2c6fef;
  color: #ffffff;
}

#data tbody > tr:last-child > td {
  border-bottom: 0;
}
