.labelTitleIntroductionBold {
  font-family: "Open Sans";
  font-size: 35px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.labelSecondTitleIntroduction {
  font-family: Open Sans;
  font-size: 29px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #88b0ff;
}
.labelThirdTitleIntroduction {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.loginSignUpTitleLabel {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #203f7a;
}
.labelAdvanced {
  font-family: Open Sans;
  font-size: 2.11vw;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  color: #022361;
}

.destinedForLabel {
  font-family: Open Sans;
  font-size: 1.89vw;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  color: #266bef;
}

.smallerLabelLandingPage {
  font-family: Open Sans;
  font-size: 1.085vw;
  font-weight: 100;
  line-height: 54px;
  letter-spacing: 0em;
  color: #000;
}


@media only screen and (max-width: 1100px) {
  .labelAdvanced {font-size: 22px;}
  .destinedForLabel {font-size: 20px;}
  .smallerLabelLandingPage {font-size: 11.5px;}
}
@media only screen and (max-width: 1400px) {
  .showAxisX{overflow-x: auto !important; }
}

.createNewAccountLabel {
  color: 	#9F9F9F;
  text-align: center;
}

.labelForgotPass {
  font-family: Open Sans;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  color: #1e3b72;
}
.labelForgotPass:hover {
  font-family: Open Sans;
  text-decoration: underline;
}

.epsiPerformanceLabel {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  color: #ffffff;
}

.footerLabels {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.termsOfUseTitleLabel {
  font-family: Open Sans;
  font-size: 26px;
  font-weight: 900;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.termsOfUseParagraphsTitles {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.termsOfUseParagraphsContent {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.signUpAndPassResetTitle {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}
.feedBackTitle {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

.sideMenuLabels {
  margin-left: 40px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.titleSettingsLabel {
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.settingSubtitlesLabels {
  margin-left: 52px;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.benchMarkingOrChatLabel {
  color: #fff;
  font-family: Open Sans;
  font-size: 24.727px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.benchMarkingTitlesLabelRed {
  margin-top: 8px;
  margin-left: 10px;
  color: red;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: red;
}

.benchMarkingTitlesLabelGreen {
  margin-top: 8px;
  margin-left: 10px;
  color: red;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: green;
}

.successCreationLabel {
  color: #70ad47;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.activationLinkText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.settingDataLabels {
  margin-left: 52px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.paymentTimeLapseLabel {
  margin-left: 52px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");

.senderLabel {
  margin-left: 14px;
  margin-top: 1px;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 900;
}
.senderLabelMessage {
  margin-left: 43px;
  font-family: "Noto Serif", serif;
  font-size: 14px;
  font-weight: 500;
  min-height: 40px;
  word-wrap: break-word;
  max-width: 100%
}

.mathQuestionsLabelInChat {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #0d41a6;
}

.cardNumberLabel {
  font-family: Open Sans;
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.cardExpiresLabel {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #a9a5a6;
}

.deleteCardLabel {
  cursor: pointer;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #2c6fef;
}

.cardBrandLabel {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.addToCreditBalanceAndPaymentMethodLabels {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.currencySignLabel {
  color: #000;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  align-items: center;
  justify-content: center;
  display: table-cell;
  vertical-align: middle;
}

.amountToEnterLabelIndicator {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.addPaymentMethodLabel {
  cursor: pointer;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #2c6fef;
}

.pricingLabel {
  color: #2c6fef;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.totalAmountLabel {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
